import React, { useContext, useEffect } from "react";
import { Context as CategoryContext } from "../contexts/CategoryContext";
import { Context as BrandContext } from "../contexts/BrandContext";
import "./styles/CategoriesPageStyles.css"
import Card from "../components/card/category";
import {AuthenticatedLayout as Layout} from "./_layouts/authenticated";
import Title from "../components/title";
import Carrusel from "../components/carrusel";
import { match } from "react-router-dom";

interface CategoriesPageParams {
  id: any;
}

interface CategoriesPageProps {
  match?: match<CategoriesPageParams>;
}

export const CategoriesPage: React.FC<CategoriesPageProps> = (props) => {
  const {
    state: { categories },
    fetchCategoriesByIdBrand,
  } = useContext(CategoryContext);
  const {
    state: { brand },
    fetchBrandById,
  } = useContext(BrandContext);

  useEffect(() => {
    fetchCategoriesByIdBrand(props.match?.params.id);
    fetchBrandById(props.match?.params.id);
  }, [props.match?.params.id]);

  sessionStorage.removeItem('savedFilterName');
  sessionStorage.removeItem('savedAttributeName');
  
  return (
    <Layout >
      <>
        <Title title={`${brand.name} - CATEGORIAS`} />
        {categories.length ? (
          <Carrusel slidesToShow={categories.length > 4 ? 4 : categories.length}>
            {categories.map(function (category: any) {
              return (
                <Card
                  urlRedirect={`/marcas/${props.match?.params.id}/categorias/${category.id}/productos`}
                  key={category.id}
                  id={props.match?.params.id}
                  title={category.name}
                  imageId={category.imageId}
                ></Card>
              );
            })}
          </Carrusel>
        ) : null}
      </>
    </Layout>
  );
};
