import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./galeryImages.css"

interface IImage {
  original: string;
  thumbnail: string;
}

interface ISidebarProps {
  images: IImage[];
}

export default function GaleryImages({ images }: ISidebarProps) {
  return (
    <ImageGallery
      thumbnailPosition="right"
      disableSwipe={false}
      infinite={false}
      showNav={false}
      useTranslate3D={false}
      showPlayButton={false}
      showFullscreenButton={false}
      items={images}
      slideDuration={0}
    />
  );
}
