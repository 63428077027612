import { useState } from "react";
import api from "../services/api";

export const useFilters = ():
  [(brandId:string, categoryId: string) => Promise<void>, never[], string] => {
  const [filters, setFilters] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const getFilters = async (brandId:string, categoryId: string) => {
    try {
      setErrorMessage("");
      const response = await api.get(`/category/${brandId}/${categoryId}/filters`);
        setFilters(response.data)
    } catch (error) {
      setErrorMessage("Something went wrong");
    }
  };

  return [getFilters, filters, errorMessage];
};
