import React, { useEffect, useContext, useState } from "react";
import api from "../../services/api";
import "./filter.css";
import { useFilters } from "../../hooks/useFilters";
import { Context as ProductContext } from "../../contexts/ProductContext";
import Image from "../image";

interface IFiltersProps {
  categoryId: string;
  brandId: string;
}

declare global {
  interface Window { stateController: any; }
}

export default function Filters({ categoryId, brandId }: IFiltersProps) {
  const [getFilters, filters] = useFilters();
  const [selected, setSelected] = useState(null);
  const {
    state: { products },
    fetchProductsByFilterAndCategory2,
    fetchProductByIdBrandCategory
  } = useContext(ProductContext);
  let productIdentificators: any = [];

  const filterHandler = (e: any, categoryId: number, brandId: number, attribute: any, productId: any, characteristic: any) => {
    e.preventDefault();
    setSelected(characteristic);
    console.log(characteristic);//para control
    console.log(window.stateController);//para control
    sessionStorage.removeItem('savedFilterName');
    sessionStorage.removeItem('savedAttributeName');
    sessionStorage.setItem('savedFilterName', attribute);
    sessionStorage.setItem('savedAttributeName', characteristic);
    if (characteristic === window.stateController) {
      fetchProductByIdBrandCategory(brandId, categoryId)
      //fetchProductsByFilterAndCategory2(categoryId, attribute, productIdentificators[0], characteristic);
      setSelected(null);
      characteristic = null;
      sessionStorage.removeItem('savedFilterName');
      sessionStorage.removeItem('savedAttributeName');
    } else {
      console.log(productId);//para control
      fetchProductsByFilterAndCategory2(categoryId, attribute, productIdentificators[0], characteristic);
    }
    window.stateController = characteristic;
  };

  useEffect(() => {
    getFilters(brandId, categoryId);
  }, []);

  useEffect(() => {
    let searchFilter: any;
    let searchAttribute: any;
    let savedFilterName = sessionStorage.getItem('savedFilterName');
    let savedAttributeName = sessionStorage.getItem('savedAttributeName');
    // console.log(savedFilterName, savedAttributeName);//para control
    if (savedFilterName != null || savedFilterName != undefined) {
      searchFilter = filters.find((x: any) => x.name === savedFilterName);
      if (savedAttributeName != null || savedAttributeName != undefined) {
        searchAttribute = searchFilter?.attributes.find((x: any) => x.name === savedAttributeName);
      }
    }
    if (searchFilter?.name && searchAttribute?.name) {
      setSelected(searchAttribute.name);
      fetchProductsByFilterAndCategory2(parseInt(categoryId), searchFilter.name, productIdentificators[0], searchAttribute.name);
      window.stateController = searchAttribute.name;
    }
  }, [filters]);

  return (
    <div className="filter">
      <h3>Caracteristicas</h3>
      {filters.length
        ? filters.map(function (filter: any) {
          productIdentificators.push(filter.productIds);
          return (
            <div key={filter.name}>
              <h4>{filter.name}</h4>
              <ul>
                {filter.attributes.map(function (attribute: any) {
                  return (
                    <li
                      key={filter.name + attribute.name}
                      style={{
                        fontFamily: selected === attribute ? "bold" : "book",
                      }}
                    >
                      { }
                      <a
                        style={{
                          fontWeight: selected === attribute.name ? "bold" : "normal",
                          color: selected === attribute.name ? "#000000" : "#323232",
                          textDecoration: selected === attribute.name ? "underline" : "none",
                        }}
                        href="/"
                        onClick={(e) =>
                          filterHandler(e, +categoryId, +brandId, filter.name, attribute.productId, attribute.name)
                        }
                      >
                        {attribute.imageId !== null ? (
                          <>
                            <Image imageId={attribute.imageId} />
                            &nbsp;
                          </>
                        ) : null}
                        {attribute.name} <span>({attribute.count})</span>
                      </a>
                    </li>
                  );
                })}
                <li></li>
              </ul>
            </div>
          );
        })
        : null}
    </div>
  );
}
