import createDataContext from "./createDataContext";
import api from "../services/api";

const nutritionReducer = (state: any, action: any) => {
    switch (action.type) {
        case "fetch_nutrition":
            return { ...state, nutritions: action.payload };
        default:
            return state;
    }
};

const fetchnutritions = (dispatch: any) => async () => {
    const response = await api.get("/nutrition");
    dispatch({ type: "fetch_nutrition", payload: response.data });
};

export const { Context, Provider } = createDataContext(
    nutritionReducer,
    { fetchnutritions },
    { nutritions: [] }
);