import React from "react";
import "./nutritionFacts.css";
import Maximize from "@spectrum-icons/workflow/Maximize";
import Table from "./table";
import Notes from "./notes";
import ReactTooltip from 'react-tooltip';
import copyImage from "../../assets/images/icons8-copy-20.png";

import {
  ActionButton,
  Content,
  Dialog,
  DialogTrigger,
} from "@adobe/react-spectrum";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NutritionFacts } from "../../ts/types/nutritionFactTypes";

interface INutritionFactsProps {
  nutritionFacts: NutritionFacts;
}

export default function NutritionFactsCard({
  nutritionFacts,
}: INutritionFactsProps) {
  function copyToClipboard() {
    const elTable = document.querySelector(".nutritionFactCard");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel?.removeAllRanges();

      try {
        range.selectNodeContents(elTable!);
        sel?.addRange(range);
      } catch (e) {
        range.selectNode(elTable!);
        sel?.addRange(range);
      }

      document.execCommand("copy");
    }
    sel?.removeAllRanges();
  }

  return (
    <>
      {nutritionFacts ? (
        <div className="nutritionFactCard">
          <Table
            nutrientsPercentage="30%"
            className="nutritionFactTable"
            nutritionFacts={nutritionFacts}
          />
          <Notes
            className="nutritionFactTableText"
            note={nutritionFacts?.observations}
          />
          <div className="nutritionFactTableFooter">
            <ReactTooltip />
            <span>Tabla nutricional</span>
            <div onClick={() => copyToClipboard()} className="copyToClipboard" data-tip="Copiar Tabla Nutricional" >
              <img src={copyImage} alt="copy table" className="copyImage" />
            </div>
            <div className="resize">
              <DialogTrigger isDismissable type="modal" >
                <ActionButton data-tip="Maximizar">
                  <Maximize />
                </ActionButton>
                <Dialog size="L" id="dialogNutritionFact">
                  <Content>
                    <Table
                      nutrientsPercentage="20%"
                      className="nutritionFactModal"
                      nutritionFacts={nutritionFacts}
                    />
                    <Notes
                      className="nutritionFactModalText"
                      note={nutritionFacts?.observations}
                    />
                  </Content>
                </Dialog>
              </DialogTrigger>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
