import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage";
import { CategoriesPage } from "./pages/CategoriesPage";
import { ProductsByCategoryPage } from "./pages/ProductsByCategoryPage";
import { ProductDetailPage } from "./pages/ProductDetail";
import { NotFound } from "./pages/NotFound";
import { BrandsPage } from "./pages/BrandsPage";
import { ComparisonPage } from "./pages/ComparisonPage";

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/marcas" component={BrandsPage} />
        <Route exact path="/marcas/:id/categorias" component={CategoriesPage}/>
        <Route exact path="/marcas/:bId/categorias/:id/productos" component={ProductsByCategoryPage}/>
        <Route exact path="/producto/:id" component={ProductDetailPage} />
        <Route exact path="/comparador" component={ComparisonPage} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
