import React, { useContext, useEffect } from "react";
import Logo from "../logo";
import "./header.css";
import Search from "../search";
import { Context as ProductContext } from "../../contexts/ProductContext";
import { useHistory, Link as RouterLink } from "react-router-dom";
import User from "../user";

interface IHeaderProps {
  showUser: boolean;
  username? : string;
}

export default function Header(props: IHeaderProps) {
  const history = useHistory();
  const {
    state: { products_search },
    fetchProductsByAutoComplete,
  } = useContext(ProductContext);

  useEffect(() => {
    fetchProductsByAutoComplete();
  }, []);

  const handleClick = (product: any) =>
    history.push(`/producto/${product.key}`);

  return (
    <header>
      <div className="header-logo">
        <Logo />
      </div>
      <div className="header-comparison">
        <RouterLink to="/comparador">
          <p className="navbar-comparison-link">Comparar Productos</p>
        </RouterLink>
      </div>
      <div className="header-search">
        <Search
          suggestions={
            products_search && products_search.length
              ? products_search.map(function (result: any) {
                  return {
                    text: result.denomination,
                    key: result.id,
                    handler: handleClick,
                      code: result.code,
                      published:result.published
                  };
                })
              : [""]
          }
        ></Search>
      </div>
      <div className="user">{props.showUser ? <User username={props.username!} /> : null}</div>
    </header>
  );
}
