import React from "react";
import {AuthenticatedLayout as Layout} from "./_layouts/authenticated";
import { Image } from "@adobe/react-spectrum";
import NotFoundImage from "../assets/images/error 404.jpg";
import "./styles/NotFoundImage.css";

import { Flex, View } from "@adobe/react-spectrum";

export const NotFound: React.FC = () => {
  return (
    <Layout>
      <Flex direction="column" gap="size-800">
        <Flex direction="row" gap="size-1600">
          <View flex>
            <Image width="720px" src={NotFoundImage} alt="Not found" />
          </View>
          <View width="40%">
            <div className="textNotFound">
              <h2>No pudimos encontrar la página que buscabas…</h2>
              <h2>¡Pero no te preocupes!</h2>
              <span>
                Te sugerimos volver al <a href="/marcas">inicio</a>
              </span>
            </div>
          </View>
        </Flex>
      </Flex>
    </Layout>
  );
};
