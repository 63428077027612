import React, { useContext } from "react";
import { Context as ProductContext } from "../../contexts/ProductContext";
import { apiUrl } from "../../services/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Table from "../nutritionFacts/table";
import Notes from "../nutritionFacts/notes";
import "./productPdf.css";

interface IProductPdfProps {
  buttonClassName: string;
}

export default function ProductPdf({ buttonClassName }: IProductPdfProps) {
  const {
    state: { product },
  } = useContext(ProductContext);

  var position = 20;

  function printDocument() {
    const input = document.getElementById("print");
    const section1Print = document.getElementById("section1Print");
    const section2Print = document.getElementById("section2Print");
    const section3Print = document.getElementById("section3Print");
    const section4Print = document.getElementById("section4Print");

    var doc = new jsPDF("p", "mm", "legal");

    html2canvas(section1Print!, { scrollY: -window.scrollY, useCORS: true }).then(
      (canvas) => {
        var imgData = canvas.toDataURL("image/jpeg", 1);
        var imgWidth = 200;
        var pageHeight = 280;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var position = 0;

        doc.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight - 15;
          doc.addPage();
          doc.addImage(imgData, "PNG", 20, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        html2canvas(section2Print!, { scrollY: -window.scrollY, useCORS: true }).then(
          (canvas2) => {
            doc.addPage();
            var imgData = canvas2.toDataURL("image/jpeg", 1);
            var imgWidth = 170;
            var pageHeight = 280;
            var imgHeight = (canvas2.height * imgWidth) / canvas2.width;
            var heightLeft = imgHeight;

            doc.addImage(imgData, "PNG", 20, 20, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            html2canvas(section3Print!, { scrollY: -window.scrollY, useCORS: true }).then(
              (canvas3) => {
                doc.addPage();
                var imgData = canvas3.toDataURL("image/jpeg", 1);
                var imgWidth = 170;
                var pageHeight = 280;
                var imgHeight = (canvas3.height * imgWidth) / canvas3.width;
                var heightLeft = imgHeight;

                doc.addImage(imgData, "PNG", 20, 0, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                html2canvas(section4Print!, { scrollY: -window.scrollY, useCORS: true }).then(
                  (canvas4) => {
                    doc.addPage();
                    var imgData = canvas4.toDataURL("image/jpeg", 1);
                    var imgWidth = 170;
                    var pageHeight = 280;
                    var imgHeight = (canvas4.height * imgWidth) / canvas4.width;
                    var heightLeft = imgHeight;

                    doc.addImage(imgData, "PNG", 20, 0, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    doc.save(`${product.denomination}.pdf`);
                  });
              });
          });
      }
    );
  }

  let departments: string[] = [];
  function orderdedCharacteristics(characteristicsProduct: any) {
    departments = [];
    let orderedCharac: any = characteristicsProduct.sort((a: any, b: any) => {
      return a.characteristic.businessUnit.order - b.characteristic.businessUnit.order
    })
    for (let i = 0; i < orderedCharac.length; i++) {
      departments = [...departments, orderedCharac[i].characteristic.businessUnit.denomination]
    }
    let uniqueArray: string[] = departments.filter(function (item, pos) {
      return departments.indexOf(item) === pos;
    })
    return (uniqueArray.map((charaTitle: string, index: number) => {
      return (
        <div key={index}>
          <h3 className="characteristicTitlePdf">{charaTitle}</h3>
          {
            characteristicsProduct.map((characteristic: any) => {
              if (charaTitle === characteristic.characteristic.businessUnit.denomination) {
                return (
                  <div key={characteristic.id} className="characteristicPositionPdf">
                    <li>
                      <b className="characteristicNamePdf">{characteristic.characteristic.denomination}:{" "}</b>
                      <span className="characteristicAttributePdf">{characteristic.description}</span>
                    </li>
                  </div>
                );
              }
            })
          }
        </div>
      )
    }))
  }

  function ingredientComment(ingredientValue: string) {
    if (ingredientValue != "-" && ingredientValue !== null) {
      return <>&nbsp;{ingredientValue}</>
    }
  }

  return (
    <>
      <div style={{ overflow: "hidden", height: 0 }}>
        <div id="print">
          <div className="export">
            <div id="section1Print">
              <h1 className="titleProductDetailPdf" id="printTitle">{product.denomination}</h1>
              <h3 className="codeProductDetailPdf" id="printcode">
                Código del producto: {product.code}
              </h3>
              <div >
                <img id="printImage"
                  alt="imgProduct"
                  className="imagePdf"
                  src={`${apiUrl}/image/${product.imageId}`}
                ></img>
              </div>
            </div>
            <div id="section2Print">
              <h2 className="caractProductTitlePdf">
                Características del producto
              </h2>
              <h3 className="productDescriptionPdf">{product.description}</h3>
              <div className="characteristicsPdf">
                <ul>
                  {
                    orderdedCharacteristics(product?.characteristicsProduct)
                  }
                </ul>
              </div>
              {!!product.richText ?
                <div
                  style={{ width: "650px" }}
                  dangerouslySetInnerHTML={{
                    __html: product.richText,
                  }}
                />
                : null}
            </div>
            <div id="section3Print">
              {product.nutritionFacts ? (
                <>
                  <div className="spaceOptimizer"></div>
                  <Table
                    nutrientsPercentage="20%"
                    className="nutritionFactTablePdf"
                    nutritionFacts={product.nutritionFacts}
                  />
                  {product.nutritionFacts.observations > 0 ?
                    <Notes
                      className="nutritionFactTextPdf"
                      note={product.nutritionFacts.observations}
                    /> : <></>}
                </>
              ) : null}
            </div>
            <div id="section4Print">
              <br />
              <h2 className="ingredientProductTitlePdf">
                Ingredientes
              </h2>
              {product.productIngredient != null && product.productIngredient.length === 0 ?
                <div className="richTextStylePdf"
                  dangerouslySetInnerHTML={{
                    __html: product.ingredientContent,
                  }}>
                </div>
                : product.productIngredient.length > 0 ?
                  <div>
                    {product.productIngredient.map((ingredient: any, index: number, array: any) => {
                      if (ingredient.levelI === 1 && ((index + 1) === array.length || (product.ingredientContent === null || product.ingredientContent.length === 0))) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          .&nbsp;</span>
                      } else if (index === 0 && ingredient.ingredientType === "Puro") {
                        return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}
                          {ingredientComment(ingredient.ingredientValue)}
                          ,&nbsp;</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 1) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          ,&nbsp;</span>
                      } else if (index === 0 && ingredient.ingredientType === "Función") {
                        return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}{" ("}</span>
                      } else if (ingredient.ingredientType === "Función") {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}{" ("}</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 2 && (index + 1) === array.length) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          {")."}&nbsp;</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && (array[index + 1] != undefined && array[index + 1].levelI === ingredient.levelI - 1)) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          {"),"}&nbsp;</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 3 && (index + 1) === array.length) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          {"))."}&nbsp;</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && array[index + 1].levelI === 1) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          {")),"}&nbsp;</span>
                      } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1) {
                        return <span key={ingredient.id}>{ingredient.ingredient.name}
                          {ingredientComment(ingredient.ingredientValue)}
                          ,&nbsp;</span>
                      }
                    })}
                    {product.ingredientContent != null && product.ingredientContent.length > 0 ?
                      <span><strong>{product.ingredientContent}</strong></span>
                      : null
                    }
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
      <button className={buttonClassName} onClick={() => printDocument()}>
        Descargar como pdf
      </button>
    </>
  );
}
