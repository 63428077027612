import React from "react";
import { apiUrl } from "../../../services/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./comparedPdf.css";
import { Nutrition } from "../../../ts/types/nutritionFactTypes";
import { Level } from "../../../ts/enums/NutritionFactLevel";

interface IProductPdfProps {
  buttonClassName: string;
  productsToCompare?: any;
}

export default function ComparedPdf({ buttonClassName, productsToCompare }: IProductPdfProps) {

  function printDocument() {
    const input = document.getElementById("print");
    var clientHeight = input!.clientHeight;
    var clientWidth = input!.clientWidth;

    html2canvas(input!, { scrollX: 0, scrollY: -window.scrollY, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'px', [clientWidth, clientHeight]);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, 'jpeg', 0, 0, width, height);
      pdf.save(`Productos-Comparados.pdf`);
    });
  }

  function getIdentationClassByLevel(level: Level) {
    if (level === Level.FIRST)
      return "firstLevelIdentation";
    if (level === Level.SECOND)
      return "secondLevelIdentation";
    else
      return "thirdLevelIdentation"
  }

  let departments: string[] = [];
  function orderdedCharacteristics(characteristicsProduct: any) {
    departments = [];
    let orderedCharac: any = characteristicsProduct.sort((a: any, b: any) => {
      return a.characteristic.businessUnit.order - b.characteristic.businessUnit.order
    })
    for (let i = 0; i < orderedCharac.length; i++) {
      departments = [...departments, orderedCharac[i].characteristic.businessUnit.denomination]
    }
    let uniqueArray: string[] = departments.filter(function (item, pos) {
      return departments.indexOf(item) === pos;
    })
    return (uniqueArray.map((charaTitle: string, index: number) => {
      return (
        <div key={index}>
          <h3 className="characteristicTilte-comparePdf">{charaTitle}</h3>
          {
            characteristicsProduct.map((characteristic: any) => {
              if (charaTitle === characteristic.characteristic.businessUnit.denomination) {
                return (
                  <div key={characteristic.id}>
                    <li>
                      <b className="characteristicName-comparePdf">{characteristic.characteristic.denomination}:{" "}</b>
                      <span className="characteristicAttribute-comparePdf">{characteristic.description}</span>
                    </li>
                  </div>
                );
              }
            })
          }
        </div>
      )
    }))
  }

  function ingredientComment(ingredientValue: string) {
    if (ingredientValue != "-" && ingredientValue !== null) {
      return <>&nbsp;{ingredientValue}</>
    }
  }

  return (
    <>
      <button className={buttonClassName} onClick={() => printDocument()}>
        Descargar como pdf
      </button>
      <div style={{ overflow: "hidden", height: 0 }}>
        <div id="print">
          <h1 style={{ textAlign: "center" }}>Productos Comparados</h1>
          <div className="pdfCards-flex">
            {
              productsToCompare.map((product: any) => {
                return (
                  <div className="pdfCards-compared" key={product.id}>
                    <div
                      className="pdfCards-compared-image"
                      style={{
                        backgroundImage: `url('${apiUrl}/image/${product.imageId}')`,
                      }}
                    ></div>
                    <div className="pdfCards-compared-content">
                      <h3>{product.denomination}</h3>
                      <h4>
                        Código del producto: {product.code}
                      </h4>
                      <h4>{product.description}</h4>
                      <h4><u>Características del producto:</u></h4>
                      <ul>
                        {
                          orderdedCharacteristics(product?.characteristicsProduct)
                        }
                      </ul>
                    </div>
                    <div className="pdfRichTextStyleComp"
                      dangerouslySetInnerHTML={{
                        __html: product.richText,
                      }}
                    />
                    <div className="pdfNutritionFactCardComp">
                      <div className="pdfNutritionFactTableComp">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "30%" ?? "45%" }}>Nutrientes</th>
                              <th style={{ width: "15%", textAlign: "center" }}>{product.nutritionFacts.hundredUnit}</th>
                              <th style={{ width: "15%", textAlign: "center" }}>Porción {product.nutritionFacts.portion}</th>
                              <th style={{ width: "10%", textAlign: "center" }}>%VD(*)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {product.nutritionFacts.nutritions.map(function (nutrition: Nutrition) {
                              return (
                                <tr key={nutrition.id}>
                                  <td style={{ width: "30%" ?? "45%" }} className={getIdentationClassByLevel(nutrition.level)}>{nutrition.name}</td>
                                  <td style={{ width: "15%", textAlign: "center" }}>
                                    {nutrition.hundredValue}
                                  </td>
                                  <td style={{ width: "15%", textAlign: "center" }}>
                                    {nutrition.portionvalue}
                                  </td>
                                  <td style={{ width: "10%", textAlign: "center" }}>
                                    {nutrition.vdValue}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="pdfNutritionFactTableTextComp">
                        <span>{product.nutritionFacts?.observations}</span>
                      </div>
                    </div>
                    <div>
                      <span className="pdfIngredientComp">Ingredientes:</span>
                      <div className="pdfRichTextStyleComp">
                        {product.productIngredient != null && product.productIngredient.length === 0 ?
                          <div dangerouslySetInnerHTML={{
                            __html: product.ingredientContent,
                          }}>
                          </div>
                          : product.productIngredient.length > 0 ?
                            <div>
                              {product.productIngredient.sort((a: any, b: any) => {
                                return a.order - b.order;
                              }).map((ingredient: any, index: number, array: any) => {
                                if (ingredient.levelI === 1 && ((index + 1) === array.length || (product.ingredientContent === null || product.ingredientContent.length === 0))) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    .&nbsp;</span>
                                } else if (index === 0 && ingredient.ingredientType === "Puro") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                } else if (index === 0 && ingredient.ingredientType === "Función") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}{" ("}</span>
                                } else if (ingredient.ingredientType === "Función") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}{" ("}</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 2 && (index + 1) === array.length) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {")."}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && (array[index + 1] != undefined && array[index + 1].levelI === ingredient.levelI - 1)) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {"),"}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 3 && (index + 1) === array.length) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {"))."}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && array[index + 1].levelI === 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {")),"}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                }
                              })}
                              {product.ingredientContent != null && product.ingredientContent.length > 0 ?
                                <span><strong>{product.ingredientContent}</strong></span>
                                : null
                              }
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}