import createDataContext from "./createDataContext";
import api from "../services/api";

const brandReducer = (state, action) => {
    switch (action.type) {
        case "fetch_brands":
            return { ...state, brands: action.payload };
        case "fetch_brand_by_id":
            return { ...state, brand: action.payload };
        default:
            return state;
    }
};

const fetchBrands = (dispatch) => async () => {
    const response = await api.get("/brand");
    dispatch({ type: "fetch_brands", payload: response.data });
};

const fetchBrandById = (dispatch) => async (id) => {
    const response = await api.get(`/brand/${id}`);
    dispatch({ type: "fetch_brand_by_id", payload: response.data });
};

export const { Context, Provider } = createDataContext(
    brandReducer,
    { fetchBrands,
    fetchBrandById },
    { brands: [],
    brand: {} }
);