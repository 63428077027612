import React, { useContext, useEffect, useState } from "react";
import { Context as ProductContext } from "../contexts/ProductContext";
import { AuthenticatedLayout as Layout } from "./_layouts/authenticated";
import Breadcrumb, { IItemBreadCrum } from "../components/breadcrumb";
import GaleryImages from "../components/galeryImages";
import "./styles/productDetail.css";
import { apiUrl } from "../services/api";
// import Mail from "../components/share/mail";
// import Whatsapp from "../components/share/whatsapp";
// import * as constants from "../shared/constants";
import ProductsRelated from "../components/productsRelated";
import { match } from "react-router-dom";
import NutritionFactsCard from "../components/nutritionFacts";
import ExportProductPdf from "../components/productPdf";
import { Flex, View } from "@adobe/react-spectrum";
import "./global"
import VideoViewer, { IItems } from "../components/videos";
import 'antd/dist/antd.css';
import { Collapse } from 'antd';

const { Panel } = Collapse;

interface IFiltersProps {
  categoryId: string;
}
interface IImage {
  original: string;
  thumbnail: string;
}

interface ProductDetailPageParams {
  id: string;
}

interface ProductDetailPageProps {
  required: string;
  match?: match<ProductDetailPageParams>;
}

export const ProductDetailPage: React.FC<ProductDetailPageProps> = (props) => {
  let bootbox: any;
  const {
    state: { product },
    getProductById,
  } = useContext(ProductContext);

  const images: IImage[] = [];
  const itemsBreadCrumb: IItemBreadCrum[] = [];

  useEffect(() => {
    const id = props.match?.params.id;
    getProductById(id);
  }, [props.match?.params.id]);
  // console.log(product); //para control
  function getVideos() {
    if (product == null || !product.videoURL) {
      return [];
    }
    return product.videoURL.split(';');
  }

  function getImages() {
    const apiImages = `${apiUrl}/image/`;

    images.push({
      original: apiImages + product.imageId,
      thumbnail: apiImages + product.thumbnailId,
    });
    images.push(
      ...product.productImages.map(function (image: any) {
        return {
          original: apiImages + image.imageId,
          thumbnail: apiImages + image.thumbnailId,
        };
      })
    );
    return images;
  }

  function getItemsBreadCrumb() {
    itemsBreadCrumb.push({ label: "Marcas", urlRedirect: "/marcas" });
    //console.log(product); para control
    itemsBreadCrumb.push({
      label: "Categorías",
      urlRedirect: `/marcas/${product?.brandId}/categorias`,
    });
    itemsBreadCrumb.push({
      label: product?.category.name,
      urlRedirect: `/marcas/${product?.brandId}/categorias/${product?.categoryId}/productos`,
    });
    itemsBreadCrumb.push({
      label: product?.denomination,
      urlRedirect: `/producto/${product?.id}`,
    });

    return itemsBreadCrumb;
  }

  function getMapProps() {
    let flagIndex = 0;
    let flags = [""];
    product.flags = [];
    let productProps = product.characteristicsProduct;

    for (let i = 0; i < productProps.length; i++) {
      if (productProps[i].characteristicId == 2) {
        flags[flagIndex] = `${apiUrl}/image/` + productProps[i].imageId;
        flagIndex++;
        product.flags = flags;
      }
    }
    return product.flags;
  }

  let departments: string[] = [];
  function orderdedCharacteristics(characteristicsProduct: any) {
    departments = [];
    let orderedCharac: any = characteristicsProduct.sort((a: any, b: any) => {
      return a.characteristic.businessUnit.order - b.characteristic.businessUnit.order
    })
    for (let i = 0; i < orderedCharac.length; i++) {
      departments = [...departments, orderedCharac[i].characteristic.businessUnit.denomination]
    }
    let uniqueArray: string[] = departments.filter(function (item, pos) {
      return departments.indexOf(item) === pos;
    })
    return (uniqueArray.map((charaTitle: string, index: number) => {
      return (
        <Panel header={charaTitle} key={index}>
          <ul>
            {
              characteristicsProduct.map((characteristic: any, index: number) => {
                getMapProps();
                if (charaTitle === characteristic.characteristic.businessUnit.denomination) {
                  if (characteristic.characteristic.denomination == "Exportación" && (product.characteristicsProduct[index] == product.characteristicsProduct.length -1 || product?.characteristicsProduct[index + 1]?.characteristic.denomination != "Exportación")) {
                    return (
                      <div key={index} className="dinamicCharStyleExportation">
                        <b>Exportación: </b>
                        <span>
                          {product.flags.map((flag: any, index: number) => {
                            return (
                              <img key={index} src={flag} className="flagNormalizer" />
                            )
                          })}
                        </span>
                      </div>
                    )
                  }
                  else if (characteristic.characteristic.denomination != "Exportación") {
                    return (
                      <div key={index} className="characteristicPosition">
                        <li>
                          <b>{characteristic.characteristic.denomination}:{" "}</b>
                          <span>{characteristic.description}</span>
                        </li>
                      </div>
                    )
                  }
                }
              })
            }
          </ul>
        </Panel>
      )
    }))
  }

  function ingredientComment(ingredientValue: string) {
    if (ingredientValue != "-" && ingredientValue !== null) {
      return <>&nbsp;{ingredientValue}</>
    }
  }

  return (
    <div className="marginNormalizer" id="todo">
      {product ? (
        <Layout>
          <Breadcrumb items={getItemsBreadCrumb()} />
          <h1 className="titleProductDetail">{product?.denomination}</h1>
          <h3 className="codeProductDetail">
            Código del producto: {product?.code}
          </h3>
          <Flex direction="column" gap="size-800">
            <Flex direction="row" gap="size-1600">
              <View flex>
                <Flex direction="row" gap="size-200">
                  <>
                    {product != null ? (
                      <div className="imgPositionNormalizer">
                        <GaleryImages images={getImages()} />
                      </div>
                    ) : null}
                  </>
                </Flex>
                <Flex direction="row" gap="size-200">
                  <h2 className="caractProductTitle">
                    Características del producto
                  </h2>
                </Flex>
                <Flex direction="row" gap="size-200">
                  <h3 className="productDescription">{product?.description}</h3>
                </Flex>
                <div className="characteristics">
                  <Collapse defaultActiveKey={['0']}>
                    {
                      orderdedCharacteristics(product?.characteristicsProduct)
                    }
                  </Collapse>
                </div>
                <Flex direction="row" gap="size-200">
                  <div className="richTextStyle"
                    style={{ width: "800px" }}
                    dangerouslySetInnerHTML={{
                      __html: product.richText,
                    }}
                  />
                </Flex>
                <Flex direction="row" gap="size-600">
                  <>
                    <VideoViewer items={getVideos()} />
                  </>
                </Flex>
                <Flex direction="row" gap="size-200">
                  <div className="footContact" >
                    {/* Deshabilitado temporalmente a pedido del cliente
                    <div className="share">
                      <span>Compartir </span>
                      <Mail
                        subject={constants.TEXT_MAIL_SHARE_SUBJECT}
                        body={constants.TEXT_MAIL_SHARE_BODY+product.id}
                      />
                      <Whatsapp text={constants.TEXT_WHATSAPP_SHARE+product.id} />
                    </div> */}
                    <ExportProductPdf buttonClassName="btnExport" />
                  </div>
                </Flex>
              </View>
              <View width="40%">
                <Flex direction="column" gap="size-400">
                  <Flex direction="row" gap="size-200">
                    <>
                      {product.nutritionFacts ? (
                        <div className="nutritionFacts">
                          <NutritionFactsCard
                            nutritionFacts={product.nutritionFacts}
                          />
                        </div>
                      ) : null}
                    </>
                  </Flex>
                  <Flex direction="row" gap="size-200">
                    <h2 className="ingredientProductTitle">
                      Ingredientes
                    </h2>
                  </Flex>
                  <div>
                    {product.productIngredient != null && product.productIngredient.length === 0 ?
                      <div className="richTextStylePdf"
                        dangerouslySetInnerHTML={{
                          __html: product.ingredientContent,
                        }}>
                      </div>
                      : product.productIngredient.length > 0 ?
                        <div>
                          {product.productIngredient.map((ingredient: any, index: number, array: any) => {
                            if (ingredient.levelI === 1 && ((index + 1) === array.length || (product.ingredientContent === null || product.ingredientContent.length === 0))) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                .&nbsp;</span>
                            } else if (index === 0 && ingredient.ingredientType === "Puro") {
                              return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}
                                {ingredientComment(ingredient.ingredientValue)}
                                ,&nbsp;</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 1) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                ,&nbsp;</span>
                            } else if (index === 0 && ingredient.ingredientType === "Función") {
                              return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}{" ("}</span>
                            } else if (ingredient.ingredientType === "Función") {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}{" ("}</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 2 && (index + 1) === array.length) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                {")."}&nbsp;</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && (array[index + 1] != undefined && array[index + 1].levelI === ingredient.levelI - 1)) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                {"),"}&nbsp;</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 3 && (index + 1) === array.length) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                {"))."}&nbsp;</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && array[index + 1].levelI === 1) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                {")),"}&nbsp;</span>
                            } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1) {
                              return <span key={ingredient.id}>{ingredient.ingredient.name}
                                {ingredientComment(ingredient.ingredientValue)}
                                ,&nbsp;</span>
                            }
                          })}
                          {product.ingredientContent != null && product.ingredientContent.length > 0 ?
                            <span><strong>{product.ingredientContent}</strong></span>
                            : null
                          }
                        </div>
                        : null
                    }
                  </div>
                  <Flex direction="row" gap="size-00">
                    <ProductsRelated
                      category={product?.category.name}
                      idProduct={product?.id}
                      idCategory={product?.category.id}
                      idBrand={product?.brandId}
                    />
                  </Flex>
                </Flex>
              </View>
            </Flex>
          </Flex>
        </Layout>
      ) : null}
    </div>
  );
};
