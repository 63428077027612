import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { apiUrl } from "../../../services/api";

import "./card.css";

type CardProps = {
  title: string;
  description: string;
  imageId: number;
};

export default function Card({ title, description, imageId }: CardProps) {
  return (
    <div className="card-compare">
      <div
        className="card-compare-image"
        style={{
          backgroundImage: `url('${apiUrl}/image/${imageId}')`,
        }}
      ></div>
      <div className="card-compare-content">
        <h3>{title}</h3>
        <h4>{description}</h4>
      </div>
    </div>
  );
}
