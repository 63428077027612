import React, { useState } from "react";
import 'antd/dist/antd.css';
import { Row, Col, Select } from 'antd';

const { Option } = Select;

interface ChildComponentProps {
  brands: any;
  categories: any;
  ingredients: any;
  characteristics: any;
  nutrients: any;
  filterProducts(selection: any): any;
}

export const FilterStepOne: React.FC<ChildComponentProps> = (props) => {
  // console.log(props);//para control
  const [filters, setFilters] = useState({
    selCategory: undefined,
    selBrands: undefined,
    selCharacteristics: undefined,
    selIngredients: undefined,
    selNutrients: undefined,
  });

  const handleChange = (name: any, value: any) => {
    // console.log(name, value);//para control
    setFilters({ ...filters, [name]: value });
  };

  props.filterProducts({
    categoryId: filters.selCategory,
    brandsId: filters.selBrands,
    characteristicsName: filters.selCharacteristics,
    ingredientsId: filters.selIngredients,
    nutrientsName: filters.selNutrients,
  });

  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={12}>
          <p className="filter-title">Categoría</p>
          <div style={{ marginBottom: "1rem" }}>
            <Select placeholder="Elige categoría"
              style={{ width: '100%' }}
              allowClear
              onChange={(value) => handleChange("selCategory", value)}
              showSearch
              size="large"
              aria-labelledby="categoría"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.categories && props.categories.map((category: any) => {
                return (
                  <Option key={category.id} value={parseInt(category.id)}>{category.name}</Option>
                )
              }
              )}
            </Select>
          </div>
          <p className="filter-title">Marcas</p>
          <div style={{ marginBottom: "1rem" }}>
            <Select
              mode="multiple"
              allowClear
              size="large"
              style={{ width: '100%' }}
              aria-labelledby="marca"
              placeholder="Elige Marca/s"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleChange("selBrands", value)}
            >
              {props.brands && props.brands.map((brand: any) => {
                return (
                  <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                )
              }
              )}
            </Select>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <p className="filter-title">Características</p>
          <Select
            mode="multiple"
            allowClear
            size="large"
            style={{ width: '100%' }}
            placeholder="Elige característica/s"
            aria-labelledby="característica"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleChange("selCharacteristics", value)}
          >
            {props.characteristics && props.characteristics.map((characteristic: any) => {
              return (
                <Option key={Math.random().toString(36).substr(2, 9)} value={characteristic.description}>{characteristic.characteristic} - {characteristic.description}</Option>
              )
            }
            )}
          </Select>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={12}>
          <p className="filter-title">Ingredientes</p>
          <Select
            mode="multiple"
            allowClear
            size="large"
            style={{ width: '100%' }}
            placeholder="Elige ingrediente/s"
            aria-labelledby="ingrediente"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleChange("selIngredients", value)}
          >
            {props.ingredients && props.ingredients.map((ingredient: any) => {
              return (
                <Option key={ingredient.id} value={ingredient.id}>{ingredient.name}</Option>
              )
            }
            )}
          </Select>
        </Col>
        <Col className="gutter-row" span={12}>
          <p className="filter-title">Nutrientes</p>
          <Select
            mode="multiple"
            allowClear
            size="large"
            style={{ width: '100%' }}
            placeholder="Elige nutriente/s"
            aria-labelledby="nutriente"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => handleChange("selNutrients", value)}
          >
            {props.nutrients && props.nutrients.map((nutrient: any) => {
              return (
                <Option key={Math.random().toString(36).substr(2, 9)} value={nutrient.name}>{nutrient.name}</Option>
              )
            }
            )}
          </Select>
        </Col>
      </Row>
    </div>
  )
}