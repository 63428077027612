import React from "react";
import "./title.css"

type TitleProps = {
  title: string;
};

export default function Title({ title }: TitleProps) {
  return (
      <h1 className="title">{title}</h1>
  );
}
