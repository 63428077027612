import React, { ReactNode } from "react";

interface IContentProps {
  children: ReactNode;
}

export default function Content({ children }: IContentProps) {
  return  <div style={{width: "85%"}}>{children}</div>;
}

