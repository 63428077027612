import React, { useContext, useEffect } from "react";
import { Context as BrandContext } from "../contexts/BrandContext";
import {AuthenticatedLayout as Layout} from "./_layouts/authenticated";
import "./styles/CategoriesPageStyles.css"
import Card from "../components/card/brand";
import Title from "../components/title";
import Carrusel from "../components/carrusel";

export const BrandsPage: React.FC = () => {
    const {
        state: { brands },
        fetchBrands,
    } = useContext(BrandContext);

    useEffect(() => {
        fetchBrands();
    }, []);
    sessionStorage.removeItem('savedFilterName');
    sessionStorage.removeItem('savedAttributeName');
    return (
        <Layout >
            <>
                <Title title="Marcas" />
                {brands.length ? (
                    <Carrusel slidesToShow={brands.length > 4 ? 4 : brands.length}>
                        {brands.map(function (brand: any) {
                            return (
                                <Card
                                    urlRedirect={`/marcas/${brand.id}/categorias`}
                                    key={brand.id}
                                    title={brand.name}
                                    imageId={brand.imageId}
                                ></Card>
                            );
                        })}
                    </Carrusel>
                ) : null}
            </>
        </Layout>
    );
};