import React, { ReactNode, useContext, useEffect, useState } from "react";
import Header from "../../../components/header";
import Loader from "../../../components/loader";
import { useHistory } from "react-router-dom";
import { Context as AuthContext } from "../../../contexts/AuthContext";
import { IdleSessionTimeout } from 'idle-session-timeout';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import Footer from "../../../components/footer";

interface IProps {
  children: ReactNode;
}

export function AuthenticatedLayout({ children }: IProps) {
  const { state: { isAuthenticated, token }, logout } = useContext(AuthContext);
  const history = useHistory();
  const [version, setVersion] = useState("");
  useEffect(() => {
    if (!isAuthenticated || !sessionStorage.getItem("token")) {
      logout();
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const versionStorage = localStorage.getItem("version");
    if (versionStorage) {
      setVersion(JSON.parse(versionStorage));
    }
  }, [localStorage.getItem("version")]);

  let session = new IdleSessionTimeout(60 * 60 * 1000);
  iziToast.settings({
    timeout: false,
    resetOnHover: true,
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX'
  });

  session.onTimeOut = () => {
    if (sessionStorage.getItem("token")) {
      logout();
      console.log('Session TimeOut');
      iziToast.warning({
        title: 'Su sesión ha caducado',
        message: 'Por favor loguéese nuevamente.'
      });
    }
  };
  session.start();

  return (
    <>
      {isAuthenticated ? (
        <div>
          <Header showUser={true} username={token.nameid || "System"} />
          <Loader />
          <div style={{ margin: "25px", minHeight: "80vh", position: "relative", zIndex: 1 }}>{children}</div>
          <Footer version={version} />
        </div>
      ) : null}
    </>
  );
}