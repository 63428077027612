import React, { useContext, useEffect } from "react";
import { Context as ProductContext } from "../contexts/ProductContext";
import { Context as CategoryContext } from "../contexts/CategoryContext";
import Card from "../components/card/product";
import {AuthenticatedLayout as Layout} from "./_layouts/authenticated";
import Title from "../components/title";
import Content from "../components/content";
import SideBar from "../components/sidebar";
import Carrusel from "../components/carrusel";
import Filters from "../components/filters";
import { match } from "react-router-dom";
import Breadcrumb, { IItemBreadCrum } from "../components/breadcrumb";

interface ProductsByCategoryPageParams {
    id: any;
    bId: any;
}

interface ProductsByCategoryPageProps {
    match?: match<ProductsByCategoryPageParams>;
}

export const ProductsByCategoryPage: React.FC<ProductsByCategoryPageProps> = (props) => {
    const {
        state: { products },
        fetchProductByIdBrandCategory
    } = useContext(ProductContext);

    const {
        state: { categories },
        fetchCategoriesByIdBrand
    } = useContext(CategoryContext);

    useEffect(() => {
        const id = props.match?.params.id;
        fetchProductByIdBrandCategory(props.match?.params.bId, id).then(fetchCategoriesByIdBrand(props.match?.params.bId));
    }, []);
    
    const itemsBreadCrumb: IItemBreadCrum[] = [];
    function getItemsBreadCrumb() {
        itemsBreadCrumb.push({ label: "Marcas", urlRedirect: "/marcas" });
        itemsBreadCrumb.push({
            label: "Categorías",
            urlRedirect: `/marcas/${props.match?.params.bId}/categorias`,
        });
        itemsBreadCrumb.push({
            label: "Productos",
            urlRedirect: `/marcas/${props.match?.params.bId}/categorias/${props.match?.params.id}/productos`,
        });
        return itemsBreadCrumb;
    }

    return (
        <Layout>
            <>
                <Breadcrumb items={getItemsBreadCrumb()} />
                <div>
                    {categories.length ? (
                        <Title
                            title={categories.find((x: any) => x.id == props.match?.params.id).name}
                        />
                    ) : null}
                </div>
                <div style={{ display: "flex" }}>
                    <SideBar>
                        <Filters brandId={props.match?.params.bId} categoryId={props.match?.params.id!} />
                    </SideBar>
                    <Content>
                        {products && products.length ? (
                            <Carrusel
                                slidesToShow={products.length > 3 ? 3 : products.length}
                            >
                                {products.map(function (product: any) {
                                    return (
                                        <Card
                                            id={props.match?.params.bId}
                                            key={product.id}
                                            code={product.code}
                                            title={product.denomination}
                                            description={product.description}
                                            imageId={product.imageId}
                                            urlRedirect={`/producto/${product.id}`}
                                        ></Card>
                                    );
                                })}
                            </Carrusel>
                        ) : null}
                    </Content>
                </div>
            </>
        </Layout>
    );
};
