import * as React from "react";
import { Image } from "@adobe/react-spectrum";
import { Link as RouterLink } from "react-router-dom";

import logo from "../../assets/images/logo_2021.svg";

export default function Logo() {
  return (
    <RouterLink to="/marcas">
      <Image width="140px" src={logo} alt="logo" />
    </RouterLink>
  );
};
  