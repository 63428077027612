import React, { useState, useContext } from "react";
import { UnauthenticatedLayout as Layout } from "./_layouts/unauthenticated";
import { Flex, TextField, Button, Text } from "@adobe/react-spectrum";
import { Context as AuthContext } from "./../contexts/AuthContext";
import { User } from "./../ts/types/user";
import Logo from "../components/logo";
import "./styles/landing.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export const LandingPage: React.FC = () => {
  const {
    state: { errorMessage },
    login, cleanError
  } = useContext(AuthContext);

  let cleanUser: User = {
    Username: "",
    Password: "",
  };
  const [user, setUser] = useState<User>(cleanUser);

  let userIsValid = () => user.Username !== "" && user.Password !== "";

  function handleChangeTextUser(value: string) {
    cleanError();
    setUser({
      ...user,
      Username: value.slice(0, 30),
    });
  }

  function handleChangeTextPassword(value: string) {
    cleanError();
    setUser({
      ...user,
      Password: value.slice(0, 30),
    });
  }

  function onClick() {
    if (userIsValid()) {
      login(user);
    }
    return;
  }

  const [visible, setVisible] = useState(false);
  function visiblePassword() {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }

  return (
    <Layout>
      <div className="landing-image">
        <div className="landing-logo">
          <Logo />
        </div>
        <div className="landing-text">
          <h1>Catálogo de Productos</h1>
          <Flex
            direction="row"
            alignContent="center"
            justifyContent="center"
          >
            <div className="borderLogin">
              <h3>Inicie sesión para ver los productos</h3>
              <Flex direction="column" id="formLogin">
                <Flex direction="row" gap="size-200" wrap UNSAFE_className="formInput">
                  <TextField
                    label="Legajo"
                    id="fieldUser"
                    width="100%"
                    maxWidth="100%"
                    height="40px"
                    maxLength={30}
                    value={user.Username}
                    onChange={handleChangeTextUser}
                  />
                </Flex>
                <Flex direction="row" gap="size-200" alignItems="center" wrap UNSAFE_className="formInputPass">
                  {visible ? (
                    <>
                      <TextField
                        label="Contraseña"
                        id="fieldPassword"
                        width="100%"
                        maxWidth="100%"
                        height="40px"
                        type="text"
                        maxLength={30}
                        value={user.Password}
                        onChange={handleChangeTextPassword}
                      />
                      <EyeInvisibleOutlined className="eye-icon" onClick={visiblePassword} />
                    </>
                  ) : (
                    <>
                      <TextField
                        label="Contraseña"
                        id="fieldPassword"
                        width="100%"
                        maxWidth="100%"
                        height="40px"
                        type="password"
                        maxLength={30}
                        value={user.Password}
                        onChange={handleChangeTextPassword}
                      />
                      <EyeOutlined className="eye-icon" onClick={visiblePassword} />
                    </>
                  )}
                </Flex>
                <Flex direction="row" justifyContent="center" gap="size-200" wrap id="btnSectionAuth" UNSAFE_className="formButton">
                  <Button
                    variant="primary"
                    onPress={onClick}
                    isDisabled={!userIsValid()}
                  >
                    <Text>Iniciar sesión</Text>
                  </Button>
                </Flex>
              </Flex>
              <Flex direction="row" gap="size-200" justifyContent="center" wrap UNSAFE_className="errorMessageAuth">
                {errorMessage}
              </Flex>
            </div>
          </Flex>
        </div>
      </div>
    </Layout>
  );
};