import React from "react";
import { Breadcrumbs, Item } from "@adobe/react-spectrum";
import { useHistory } from "react-router-dom";

export interface IItemBreadCrum {
  urlRedirect: string;
  label: string;
}

export interface IItems {
  items: IItemBreadCrum[];
}

export default function BreadCrumb({ items }: IItems) {
  const history = useHistory();

  function handler(i: any) {
    history.push(i);
  }

  return (
    <Breadcrumbs onAction={handler}>
      {items.map((i) => (
        <Item key={i.urlRedirect}>{i.label}</Item>
      ))}
    </Breadcrumbs>
  );
}
