import React from "react";

interface INoteProps {
  className?: string;
  note: string;
}

export default function Notes({ className, note }: INoteProps) {
  return (
    <div className={className}>
      <div>
        <span>{note}</span>
      </div>
    </div>
  );
}
