import React, { useState } from "react";
import Content from "../content";
import Carrusel from "../carrusel";
import Card from "../card/compareStepTwo";
import 'antd/dist/antd.css';
import { Checkbox } from 'antd';
import "./comparedStepThree.css";

interface ChildComponentProps {
  filteredProducts?: any;
  compareProducts(products: any): any;
}

export const FilterStepTwo: React.FC<ChildComponentProps> = (props) => {
  const [productSelection, setProductSelection] = useState<any[]>([]);
  //console.log(props);
  function onChange(product: any) {
    if (productSelection.find(e => e.id === product.id)) {
      const newSelection = productSelection.filter((item: any) => item.id !== product.id);
      setProductSelection(newSelection);
    } else {
      setProductSelection([...productSelection, product]);
    }
  }
  props.compareProducts(productSelection);
  //console.log(productSelection);// para control
  return (
    <div>
      {props.filteredProducts.length === 0 ?
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h3 className="stepTwo-NoProduct">No hay productos que coincidan con su búsqueda.</h3>
          <h3 className="stepTwo-NoProduct">Aprete en "Volver" y vuelva a intentarlo.</h3>
        </div>
        :
        <div>
          <Content>
            <h3 style={{marginLeft: "60px"}}><strong>Productos encontrados:</strong></h3>
            {props.filteredProducts && props.filteredProducts.length > 0 ? (
              <Carrusel
                slidesToShow={props.filteredProducts.length > 3 ? 3 : props.filteredProducts.length}
              >
                {props.filteredProducts.map((product: any) => {
                  return (
                    <div key={product.id}>
                      <Checkbox onChange={() => onChange(product)} />
                      <Card
                        title={product.denomination}
                        description={product.description}
                        imageId={product.imageId}
                      />
                    </div>
                  );
                })}
              </Carrusel>
            ) : null}
          </Content>
        </div>
      }
    </div>
  )
}
