import React, { useContext, useState, useEffect } from "react";
import { Context as BrandContext } from "../contexts/BrandContext";
import { Context as CategoryContext } from "../contexts/CategoryContext";
import { Context as ProductContext } from "../contexts/ProductContext";
import { Context as IngredientContext } from "../contexts/IngredientContext";
import { Context as NutritionContext } from "../contexts/NutritionContext";
import { Context as CharacteristicContext } from "../contexts/CharacteristicContext";
import {AuthenticatedLayout as Layout} from "./_layouts/authenticated";
import Title from "../components/title";
import 'antd/dist/antd.css';
import { Steps, Button } from 'antd';
import './styles/ComparisonPage.css';
import { FilterStepOne } from '../components/compareSteps/filterStepOne'
import { FilterStepTwo } from "../components/compareSteps/filterStepTwo";
import { ComparedStepThree } from "../components/compareSteps/comparedStepThree";
import ComparedPdf from "../components/compareSteps/comparedPdf/index";

const { Step } = Steps;

const steps = [
  {
    title: 'First',
    content: 'First-content',
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
];

export const ComparisonPage: React.FC = () => {
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [productsToCompare, setProductsToCompare] = useState<any[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [current, setCurrent] = useState(0);
  const [urlExcel, setUrlExcel] = useState('');

  const next = () => {
    setFilteredProducts(filterByIngredients);
    setCurrent(current + 1);
  };

  const prev = () => {
    setFilteredProducts([]);
    setDisableButton(true);
    setCurrent(current - 1);
  };

  const compare = () => {
    setCurrent(current + 1);
  };

  const reset = () => {
    setFilteredProducts([]);
    setDisableButton(true);
    setCurrent(0);
  };

  const {
    state: { brands },
    fetchBrands,
  } = useContext(BrandContext);

  const {
    state: { categories },
    fetchCategories,
  } = useContext(CategoryContext);

  const {
    state: { products },
    fetchProductComparison,
  } = useContext(ProductContext);

  const {
    state: { ingredients },
    fetchingredients,
  } = useContext(IngredientContext);

  const {
    state: { nutritions },
    fetchnutritions,
  } = useContext(NutritionContext);

  const {
    state: { characteristics },
    fetchcharacteristics,
  } = useContext(CharacteristicContext);

  useEffect(() => {
    fetchBrands();
    fetchCategories();
    fetchingredients();
    fetchnutritions();
    fetchcharacteristics();
  }, []);

  useEffect(() => {
    if (productsToCompare?.length === 2) {
      setUrlExcel(window.location.origin + `/api/file/excelTwo/${productsToCompare[0].id}&${productsToCompare[1].id}`);
    }
    else if (productsToCompare?.length === 3) {
      setUrlExcel(window.location.origin + `/api/file/excelThree/${productsToCompare[0].id}&${productsToCompare[1].id}&${productsToCompare[2].id}`);
    }
  }, [productsToCompare]);

  const [categoryId, setCategoryId] = useState<number>();
  useEffect(() => {
    if (categoryId != undefined){
      fetchProductComparison(categoryId);
    }
  }, [categoryId]);

  let filteredByCategories: any;
  let filterByBrands: any;
  let filterByCharacteristics: any;
  let filterByNutrients: any;
  let filterByIngredients: any;
  const filterProducts = (selection: any) => {
    if (selection.categoryId){
      setCategoryId(selection.categoryId);
      filteredByCategories = products;
      setDisableButton(false);  
    } else {
      setCategoryId(undefined);
      setDisableButton(true);  
    }

    if (!selection.brandsId || selection.brandsId?.length === brands?.length) {
      filterByBrands = filteredByCategories;
    } else {
      filterByBrands = filteredByCategories?.filter((el: any) => {
        return el.brandId === selection.brandsId[0]
      });
    }

    if (selection.characteristicsName !== undefined || selection.characteristicsName?.length === characteristics?.length) {
      filterByCharacteristics = filterByBrands?.filter((product: any) => {
        let productCharacteristicName: any = [];
        for (let i = 0; i < product.characteristicsProduct.length; i++) {
          productCharacteristicName.push(product.characteristicsProduct[i].description);
        }
        return selection.characteristicsName.every((elem: any) => productCharacteristicName.includes(elem));
      });
    } else {
      filterByCharacteristics = filterByBrands;
    }

    if (selection.nutrientsName !== undefined || selection.nutrientsName?.length === nutritions?.length) {
      filterByNutrients = filterByCharacteristics?.filter((product: any) => {
        let productNutrientsName: any = [];
        for (let i = 0; i < product.nutritionFacts.nutritions.length; i++) {
          productNutrientsName.push(product.nutritionFacts.nutritions[i].name);
        }
        return selection.nutrientsName.every((elem: any) => productNutrientsName.includes(elem));
      });
    } else {
      filterByNutrients = filterByCharacteristics;
    }

    if (selection.ingredientsId !== undefined || selection.ingredientsId?.length === ingredients?.length) {
      filterByIngredients = filterByNutrients?.filter((product: any) => {
        let productIngredientId: any = [];
        for (let i = 0; i < product.productIngredient.length; i++) {
          productIngredientId.push(product.productIngredient[i].ingredient.id);
        }
        return selection.ingredientsId.every((elem: any) => productIngredientId.includes(elem));
      });
    } else {
      filterByIngredients = filterByNutrients;
    }
    // console.log(filterByIngredients);// para control
  }

  const compareProducts = (products: any) => {
    setProductsToCompare(products);
  }
  
  return (
    <Layout >
      {current < steps.length - 1 ?
        <Title title="Comparar productos" />
        :
        <Title title="Comparación de productos" />
      }
      <div>
        {current < steps.length - 1 ? (
          <Steps current={current}>
            {steps.map((item) => {
              if (item.title == 'Last') {
                return <></>;
              } else {
                return <Step className="stepColor" key={item.title} />;
              }
            })}
          </Steps>
        ) : null}
        {current === 0 ?
          <div className="steps-content">
            <FilterStepOne filterProducts={filterProducts}
              categories={categories}
              brands={brands}
              ingredients={ingredients}
              characteristics={characteristics}
              nutrients={nutritions}
            />
          </div>
          : current === 1 ?
            <div className="steps-content">
              <FilterStepTwo filteredProducts={filteredProducts} compareProducts={compareProducts} />
            </div>
            :
            <ComparedStepThree productsToCompare={productsToCompare} />
        }
        <div className="steps-action">
          {current == 1 ?
            <Button shape="round" style={{ margin: '0 8px' }} onClick={() => prev()}>
              Volver
            </Button>
            : current == steps.length - 1 &&
            <div className="last-step-action">
              <Button shape="round" type="primary" onClick={() => reset()}>
                Comparar otros productos
              </Button>
              {productsToCompare?.length <= 1 ?
                <a href={urlExcel}>
                  <Button disabled type="primary">Descargar Excel</Button>
                </a>
                :
                <a href={urlExcel}>
                  <Button type="primary" className="btnExportComp">Descargar Excel</Button>
                </a>
              }
              <ComparedPdf buttonClassName="btnExportComp" productsToCompare={productsToCompare} />
            </div>
          }
          {disableButton ?
            <Button disabled shape="round" type="primary" onClick={() => next()}>
              Continuar
            </Button> :
            current === 0 && (
              <Button shape="round" type="primary" onClick={() => next()}>
                Continuar
              </Button>
            )}
          {current === 1 && productsToCompare?.length === 0 ? (
            <Button disabled shape="round" type="primary">Comparar</Button>
          )
            : current === 1 && productsToCompare?.length <= 3 ?
              (<Button shape="round" type="primary" onClick={() => compare()}>
                Comparar
              </Button>)
              : current === 1 && productsToCompare?.length > 3 ?
                (<div style={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "center" }}>
                  <Button disabled shape="round" type="primary">Comparar</Button>
                  <p className="compareWarning">Puede elegir hasta 3 productos a comparar</p>
                </div>)
                : null
          }
        </div>
      </div>
    </Layout>
  );
};