import React from "react";
import "./nutritionFacts.css";
import { NutritionFacts, Nutrition } from '../../../ts/types/nutritionFactTypes';
import { Level } from '../../../ts/enums/NutritionFactLevel'

interface INutritionFactsProps {
  nutritionFacts: NutritionFacts;
}

export default function NutritionFactsCard({ nutritionFacts }: INutritionFactsProps) {
  function getIdentationClassByLevel(level: Level) {
    if (level === Level.FIRST)
      return "firstLevelIdentation";
    if (level === Level.SECOND)
      return "secondLevelIdentation";
    else
      return "thirdLevelIdentation"
  }

  return (
    <>
      {nutritionFacts ? (
        <div className="nutritionFactCardComp">
          <div className="nutritionFactTableComp">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "30%" ?? "45%" }}>Nutrientes</th>
                  <th style={{ width: "15%", textAlign: "center" }}>{nutritionFacts.hundredUnit}</th>
                  <th style={{ width: "15%", textAlign: "center" }}>Porción {nutritionFacts.portion}</th>
                  <th style={{ width: "10%", textAlign: "center" }}>%VD(*)</th>
                </tr>
              </thead>
              <tbody>
                {nutritionFacts.nutritions.map(function (nutrition: Nutrition) {
                  return (
                    <tr key={nutrition.id}>
                      <td style={{ width: "30%" ?? "45%" }} className={getIdentationClassByLevel(nutrition.level)}>{nutrition.name}</td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {nutrition.hundredValue}
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {nutrition.portionvalue}
                      </td>
                      <td style={{ width: "10%", textAlign: "center" }}>
                        {nutrition.vdValue}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="nutritionFactTableTextComp">
            <span>{nutritionFacts?.observations}</span>
          </div>
        </div>
      ) : null}
    </>
  );
}
