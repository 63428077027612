import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { AppRoutes } from "./AppRoutes";
import * as serviceWorker from "./serviceWorker";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import { Provider as AuthProvider } from "./contexts/AuthContext";
import { Provider as CategoryProvider } from "./contexts/CategoryContext";
import { Provider as ProductProvider } from "./contexts/ProductContext";
import { Provider as BrandProvider } from "./contexts/BrandContext";
import { Provider as IngredientProvider } from "./contexts/IngredientContext";
import { Provider as NutritionProvider } from "./contexts/NutritionContext";
import { Provider as CharacteristicProvider } from "./contexts/CharacteristicContext";

ReactDOM.render(
  <Provider theme={defaultTheme} id="content" colorScheme="light" height="auto" minHeight="99vh">
    <AuthProvider>
      <BrandProvider>
        <CategoryProvider>
          <ProductProvider>
            <IngredientProvider>
              <NutritionProvider>
                <CharacteristicProvider>
                  <AppRoutes />
                </CharacteristicProvider>
              </NutritionProvider>
            </IngredientProvider>
          </ProductProvider>
        </CategoryProvider>
      </BrandProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
