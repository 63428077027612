import React from "react";

export interface IItems {
  items: string[];
}

function changeVideo() {}

const VideoViewer: React.FC<IItems> = ({ items }) => {
  const [isOpenState, setIsOpenState] = React.useState<boolean>(false);
  const [currentVideo,setCurrentVideoState] = React.useState<number>(0);
  
  
  const onClickShowMore = () => {
    setIsOpenState(!isOpenState);
  };

  //   React.useEffect(() => {
  //     if (open !== isOpenState) setIsOpenState(open)
  //   }, [open])

  return (
    <div>
      {items?.length > 0 ? (
        <div className="video">
          <iframe
            title="videoProduct"
            frameBorder="0"
            allowFullScreen
            src={items[0]}
          ></iframe>
        </div>
      ) : null}
      
      {isOpenState && <div> {items?.slice().splice(1,items.length-1).map((item: string) => {
            return (
                <div className="video">
                <iframe
                  title="videoProduct"
                  frameBorder="0"
                  allowFullScreen
                  src={item}
                ></iframe>
              </div>
            )
          })}</div>}

    {items?.length > 1 ? (
        <button id="Vermas" className="btnStandard"  onClick={() => onClickShowMore()}>
          {
            !isOpenState? "Ver Mas": "Cerrar"            
          }
        </button>
      ) : null}
    </div>
  );
};

export default VideoViewer
