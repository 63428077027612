import React, { useContext } from 'react';
import "./user.scss"
import { Context as AuthContext } from "../../contexts/AuthContext";

interface IUserProps {
  username: string;
}

export default function User({ username }: IUserProps) {
  const {
    logout
  } = useContext(AuthContext);

  return (
    <div>
      <span onClick={logout} className="closeSession">Cerrar sesión</span>
    </div>
  )
}