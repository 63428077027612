import React from 'react'

import {NutritionFacts,Nutrition} from '../../ts/types/nutritionFactTypes';
import {Level} from '../../ts/enums/NutritionFactLevel'

interface ITableProps{
    className?: string;
    nutritionFacts: NutritionFacts;
    nutrientsPercentage?: string;
}

export default function Table({nutritionFacts, className,nutrientsPercentage} : ITableProps) {

function getIdentationClassByLevel(level: Level){
  if (level === Level.FIRST)
    return "firstLevelIdentation";
  if(level === Level.SECOND)
        return "secondLevelIdentation";
  else
    return "thirdLevelIdentation"
}

return ( <div className={className}>
    <table>
      <thead>
        <tr>
          <th style={{ width: nutrientsPercentage ?? "45%" }}>Nutrientes</th>
    <th style={{ width: "15%",textAlign: "center" }}>{nutritionFacts.hundredUnit}</th>
          <th style={{ width: "15%",textAlign: "center" }}>Porción {nutritionFacts.portion}</th>
          <th style={{ width: "10%", textAlign: "center" }}>%VD(*)</th>
        </tr>
      </thead>
      <tbody>
        {nutritionFacts.nutritions.map(function (nutrition: Nutrition) {
          return (
            <tr key={nutrition.id}>
              <td style={{ width: nutrientsPercentage ?? "45%" }} className={getIdentationClassByLevel(nutrition.level)}>{nutrition.name}</td>
              <td style={{ width: "15%", textAlign: "center" }}>
                {nutrition.hundredValue}
              </td>
              <td style={{ width: "15%", textAlign: "center" }}>
                {nutrition.portionvalue}
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>
                {nutrition.vdValue}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>);
}