import createDataContext from "./createDataContext";
import api from "../services/api";

const characteristicReducer = (state: any, action: any) => {
    switch (action.type) {
        case "fetch_characteristic":
            return { ...state, characteristics: action.payload };
        default:
            return state;
    }
};

const fetchcharacteristics = (dispatch: any) => async () => {
    const response = await api.get("/characteristic");
    dispatch({ type: "fetch_characteristic", payload: response.data });
};

export const { Context, Provider } = createDataContext(
    characteristicReducer,
    { fetchcharacteristics },
    { characteristics: [] }
);