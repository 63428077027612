import React from "react";
import api from "../services/api";

const { useState, useCallback, useMemo, useEffect } = React;
const ax = api;

export default () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter((counter) => counter + 1), [
    setCounter,
  ]);
  const dec = useCallback(() => setCounter((counter) => counter - 1), [
    setCounter,
  ]);

  const interceptors = useMemo(
    () => ({
      request: (config: any) => (inc(), config),
      response: (response: any) => (dec(), response),
      error: (error: any) => (dec(), Promise.reject(error)),
    }),
    [inc, dec]
  ); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = ax.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const resInterceptor = ax.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      ax.interceptors.request.eject(reqInterceptor);
      ax.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);
  return [counter > 0];
};
