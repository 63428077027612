import createDataContext from "./createDataContext";
import api from "../services/api";
//import apimock from "../services/api-mock";

const categoryReducer = (state, action) => {
  switch (action.type) {
    case "fetch_categories":
      return { ...state, categories: action.payload };
    case "fetch_categories_by_id_brand":
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

const fetchCategories = (dispatch) => async () => {
  const response = await api.get("/category");
  dispatch({ type: "fetch_categories", payload: response.data });
  // console.log(response.data);//para control
};

const fetchCategoriesByIdBrand = (dispatch) => async (id) => {
  const response = await api.get(`/brand/${id}/categories`);
  dispatch({ type: "fetch_categories_by_id_brand", payload: response.data });
};

export const { Context, Provider } = createDataContext(
  categoryReducer,
  { fetchCategories,
    fetchCategoriesByIdBrand },
  { categories: [] }
);
