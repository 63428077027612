import React from "react";
import { apiUrl } from "../../services/api";
import "./comparedStepThree.css";
import NutritionFactsCard from "./nutritionFacts/index";
import 'antd/dist/antd.css';
import { Collapse } from 'antd';

const { Panel } = Collapse;

interface ChildComponentProps {
  productsToCompare?: any;
}

export const ComparedStepThree: React.FC<ChildComponentProps> = (props) => {
  // console.log(props);// para control
  function getMapProps(product: any) {
    let flagIndex = 0;
    let flags = [""];
    product.flags = [];
    let productProps = product.characteristicsProduct;

    for (let i = 0; i < productProps.length; i++) {
      if (productProps[i].characteristicId == 2) {
        flags[flagIndex] = `${apiUrl}/image/` + productProps[i].imageId;
        flagIndex++;
        product.flags = flags;
      }
    }
    return product.flags;
  }

  let departments: string[] = [];
  function orderdedCharacteristics(product: any) {
    departments = [];
    let orderedCharac: any = product.characteristicsProduct.sort((a: any, b: any) => {
      return a.characteristic.businessUnit.order - b.characteristic.businessUnit.order
    })
    for (let i = 0; i < orderedCharac.length; i++) {
      departments = [...departments, orderedCharac[i].characteristic.businessUnit.denomination]
    }
    let uniqueArray: string[] = departments.filter(function (item, pos) {
      return departments.indexOf(item) === pos;
    })
    return (uniqueArray.map((charaTitle: string, index: number) => {
      return (
        <Panel header={charaTitle} key={index}>
          <ul>
            {
              product.characteristicsProduct.map((characteristic: any, index: number) => {
                getMapProps(product);
                if (charaTitle === characteristic.characteristic.businessUnit.denomination) {
                  if (characteristic.characteristic.denomination == "Exportación" && (product.characteristicsProduct[index] == product.characteristicsProduct.length -1 || product?.characteristicsProduct[index + 1]?.characteristic.denomination != "Exportación")) {
                    return (
                      <div key={index} className="dinamicCharStyleExportation">
                        <b>Exportación: </b>
                        <span>
                          {product.flags.map((flag: any, index: number) => {
                            return (
                              <img key={index} src={flag} className="flagNormalizer" />
                            )
                          })}
                        </span>
                      </div>
                    )
                  }
                  else if (characteristic.characteristic.denomination != "Exportación") {
                    return (
                      <div key={index} className="characteristicPosition">
                        <li>
                          <b>{characteristic.characteristic.denomination}:{" "}</b>
                          <span>{characteristic.description}</span>
                        </li>
                      </div>
                    )
                  }
                }
              })
            }
          </ul>
        </Panel>
      )
    }))
  }

  function ingredientComment(ingredientValue: string) {
    if (ingredientValue != "-" && ingredientValue !== null) {
      return <>&nbsp;{ingredientValue}</>
    }
  }

  return (
    <>
      {props.productsToCompare.length === 0 ?
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h3>No hay productos seleccionados</h3>
          <h3>Aprete en "Comparar productos" para inciar el proceso</h3>
        </div>
        :
        <div className="cards-flex">
          {
            props.productsToCompare.map((product: any) => {
              return (
                <div className="cards-compared" key={product.id}>
                  <div
                    className="cards-compared-image"
                    style={{
                      backgroundImage: `url('${apiUrl}/image/${product.imageId}')`,
                    }}
                  ></div>
                  <div className="cards-compared-content">
                    <h3>{product.denomination}</h3>
                    <h4>
                      Código del producto: {product.code}
                    </h4>
                    <h4>{product.description}</h4>
                    <h4><u>Características del producto:</u></h4>
                    <div className="characteristics">
                      <Collapse defaultActiveKey={['0']}>
                        {
                          orderdedCharacteristics(product)
                        }
                      </Collapse>
                    </div>
                    <div className="richTextStyleComp"
                      dangerouslySetInnerHTML={{
                        __html: product.richText,
                      }}
                    />
                    <div>
                      {product.nutritionFacts ? (
                        <div className="nutritionFacts">
                          <NutritionFactsCard
                            nutritionFacts={product.nutritionFacts}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <span className="ingredientComp">Ingredientes:</span>
                      <div className="richTextStyleComp">
                        {product.productIngredient != null && product.productIngredient.length === 0 ?
                          <div dangerouslySetInnerHTML={{
                            __html: product.ingredientContent,
                          }}>
                          </div>
                          : product.productIngredient.length > 0 ?
                            <div>
                              {product.productIngredient.sort((a: any, b: any) => {
                                return a.order - b.order;
                              }).map((ingredient: any, index: number, array: any) => {
                                if (ingredient.levelI === 1 && ((index + 1) === array.length || (product.ingredientContent === null || product.ingredientContent.length === 0))) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    .&nbsp;</span>
                                } else if (index === 0 && ingredient.ingredientType === "Puro") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                } else if (index === 0 && ingredient.ingredientType === "Función") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name.charAt(0).toUpperCase() + ingredient.ingredient.name.slice(1)}{" ("}</span>
                                } else if (ingredient.ingredientType === "Función") {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}{" ("}</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 2 && (index + 1) === array.length) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {")."}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && (array[index + 1] != undefined && array[index + 1].levelI === ingredient.levelI - 1)) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {"),"}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI === 3 && (index + 1) === array.length) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {"))."}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1 && array[index + 1].levelI === 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    {")),"}&nbsp;</span>
                                } else if (ingredient.ingredientType === "Puro" && ingredient.levelI > 1) {
                                  return <span key={ingredient.id}>{ingredient.ingredient.name}
                                    {ingredientComment(ingredient.ingredientValue)}
                                    ,&nbsp;</span>
                                }
                              })}
                              {product.ingredientContent != null && product.ingredientContent.length > 0 ?
                                <span><strong>{product.ingredientContent}</strong></span>
                                : null
                              }
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
    </>
  )
};