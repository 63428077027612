import React, { ReactNode } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./carrousel.css";

import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";

interface IProps {
  children: ReactNode;
  slidesToShow: number;
}

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <ChevronRight size="XXL" id="rigth" aria-label="ChevronRight" />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ChevronLeft size="XXL" id="left" aria-label="ChevronLeft" />
    </div>
  );
}

export default function SimpleSlider({ children, slidesToShow }: IProps) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    nextArrow: <SampleNextArrow class="slick-next" />,
    prevArrow: <SamplePrevArrow class="slick-prev" />,
  };
  return (
    <div style={{ marginLeft: "60px" }}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
