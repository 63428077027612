import React from 'react';
import useAxiosLoader from '../../hooks/useAxiosLoader';
import './loader.css';

export default function Loader() {
    const [loading] = useAxiosLoader();

    return (
    <>
        {
            loading ? 
            <div className="loading"></div> : null
        }
    </>)
}
