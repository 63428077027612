import React, { ReactNode, useLayoutEffect, useRef } from "react";
import "./scrollable.css";
import ChevronUp from "@spectrum-icons/workflow/ChevronUp";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";

interface IContentProps {
  children: ReactNode;
  height: string;
  width: string;
}

export default function Scrollable({ children, height, width }: IContentProps) {
  const buttonUp = useRef<HTMLDivElement>(null);
  const buttonDown = useRef<HTMLDivElement>(null);
  const dvScroll = useRef<HTMLDivElement>(null);

  function scrollDown(
    scroll: HTMLDivElement,
    buttonUp: HTMLDivElement,
    buttonDown: HTMLDivElement,
    amountToScroll: number
  ) {
    let value = scroll.scrollTop + amountToScroll;
    scroll.scrollTop += amountToScroll;

    buttonUp.style.visibility = value > 0 ? "visible" : "hidden";
    buttonDown.style.visibility =
      value > scroll.scrollHeight - scroll.clientHeight ? "hidden" : "visible";
  }

  function scrollUp(
    scroll: HTMLDivElement,
    buttonUp: HTMLDivElement,
    buttonDown: HTMLDivElement,
    amountToScroll: number
  ) {
    let value = Math.round(scroll.scrollTop - amountToScroll);
    scroll.scrollTop -= amountToScroll;

    buttonDown.style.visibility =
      value !== scroll.scrollHeight - scroll.clientHeight
        ? "visible"
        : "hidden";
    buttonUp.style.visibility = value > 0 ? "visible" : "hidden";
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      if (dvScroll.current !== null && buttonDown.current !== null) {
        buttonDown.current.style.visibility =
          dvScroll.current.scrollTop !==
          dvScroll.current.scrollHeight - dvScroll.current.clientHeight
            ? "visible"
            : "hidden";
      }
    }, 400);
  }, []);



  return (
    <div style={{position: 'relative',width: width}}>
      <div
        ref={dvScroll}
        id="dvScroll"
        style={{
          height: height,
          overflowY: "hidden",
          scrollBehavior: "smooth"
        }}
      >
        <div
          ref={buttonDown}
          className="btnDown"
          style={{
            visibility:
            dvScroll.current &&
            dvScroll.current.scrollTop ===
              dvScroll.current.scrollHeight - dvScroll.current?.clientHeight
              ? "hidden"
              : "visible",

          }}
          onClick={() => scrollDown(
            dvScroll.current as HTMLDivElement,
            buttonUp.current as HTMLDivElement,
            buttonDown.current as HTMLDivElement,
            200
          )}
        >
          <ChevronDown size="M" id="down" aria-label="ChevronDown" />
        </div>
        <div
          ref={buttonUp}
          className="btnUp"
          style={{
            visibility: 'hidden'
          }}
          onClick={() => scrollUp(
            dvScroll.current as HTMLDivElement,
            buttonUp.current as HTMLDivElement,
            buttonDown.current as HTMLDivElement,
            200
          )}
        >
          <ChevronUp size="M" id="up" aria-label="ChevronUp" />
        </div>
        {children}
      </div>
    </div>
  );
}
