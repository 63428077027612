import createDataContext from "./createDataContext";
import api from "../services/api";

const ingredientReducer = (state: any, action: any) => {
    switch (action.type) {
        case "fetch_ingredient":
            return { ...state, ingredients: action.payload };
        default:
            return state;
    }
};

const fetchingredients = (dispatch: any) => async () => {
    const response = await api.get("/ingredient");
    dispatch({ type: "fetch_ingredient", payload: response.data });
};

export const { Context, Provider } = createDataContext(
    ingredientReducer,
    { fetchingredients },
    { ingredients: [] }
);