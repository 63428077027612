import createDataContext from "./createDataContext";
import { User } from "../ts/types/user";
import api from "../services/authApi";
import jwt_decode from "jwt-decode";

const config = {
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
};

const AuthReducer = (state: any, action: any) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: action.payload.isAuthenticated,
        errorMessage: action.payload.error,
      };
    case "logout":
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: action.payload.isAuthenticated,
        errorMessage: action.payload.error,
      };
    case "cleanError":
      return {
        ...state,
        errorMessage: "",
      };
  }
};

const logout = (dispatch: any) => async () => {
  sessionStorage.removeItem("token");
  dispatch({
    type: "logout",
    payload: { isAuthenticated: false, error: "", token: "" },
  });
}

const login = (dispatch: any) => async (user: User) => {
  let authenticated: boolean = false;
  let errorMessage: string = "";
  let token: string = "";

  await api
    .post(
      "/auth",
      {
        Username: user.Username,
        Password: user.Password,
      },
      config
    )
    .then((result) => {
      if (result.status === 200) {
        // console.log(jwt_decode(result.data))//para control
        if (sessionStorage.getItem("token") == undefined) {
          let token = ("Bearer " + result.data);
          sessionStorage.setItem("token", JSON.stringify(token));
          let version = (jwt_decode(result.data) as any);
          if(localStorage.getItem("version")){
            localStorage.removeItem("version")
          }
          localStorage.setItem("version", JSON.stringify(version.aud));
          authenticated = true;
          token = jwt_decode(result.data) as string;
          let token2 = jwt_decode(sessionStorage?.getItem("token") || '');
          window.location.href = window.location.href;
        }
      }
    })
    .catch((e) => {
      console.log(e);
      errorMessage = "El número de legajo y/o contraseña no es correcto";
    });
  dispatch({
    type: "login",
    payload: { isAuthenticated: authenticated, error: errorMessage, token: token },
  });
};

const cleanError = (dispatch: any) => async () => {
  dispatch({
    type: "cleanError"
  });
}

function getIsAuthenticated(): boolean {
  return JSON.parse(sessionStorage.getItem("token")!);
}

function getToken(): string {
  if (sessionStorage.getItem("token")) {
    let tok = jwt_decode(sessionStorage.getItem("token")!) as string;
    return tok;
  }
  return "";
}

export function getUsername(): string {
  if (sessionStorage.getItem("token")) {
    return (jwt_decode(sessionStorage.getItem("token")!) as any).nameid;
  }
  return "System";
}

export const { Context, Provider } = createDataContext(
  AuthReducer,
  { login, cleanError, logout },
  { token: getToken(), isAuthenticated: getIsAuthenticated(), errorMessage: "" }
);
