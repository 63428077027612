import React, { useContext, useEffect, useState } from "react";
import "./productsRelated.css";
import { useHistory } from "react-router-dom";
import { Context as ProductContext } from "../../contexts/ProductContext";
import { relative } from "path";

interface IProductsRelatedProps {
  idProduct: string;
  idCategory: string;
  category: string;
  idBrand: string
}

export default function ProductsRelated(props: IProductsRelatedProps) {
  const {
    state: { relatives },
    getProducsRelative,
  } = useContext(ProductContext);

  useEffect(() => {
    getProducsRelative(props.idProduct, props.idCategory);
  }, [props.idProduct]);

  const history = useHistory();
  return (
    <>
      {relatives ? (
        <div className="productsRelated">
          <h2>{props.category}</h2>
          <div className="box"></div>
          <div className="box2"></div>
          <div className="prev">
            <span
              onClick={() => history.push(`/producto/${relatives.previous.id}`)}
            >
              {relatives.previous.denomination}
            </span>
          </div>
          <div className="current">
            <span>{relatives.current.denomination}</span>
          </div>
          <div className="next">
            <span
              onClick={() => history.push(`/producto/${relatives.next.id}`)}
            >
              {relatives.next.denomination}
            </span>
          </div>
          <a
            className="showMoreProductsRelated"
            onClick={() => history.push(`/marcas/${props.idBrand}/categorias/${props.idCategory}/productos`)}
          >
            Ver más
          </a>
        </div>
      ) : null}
    </>
  );
}
