import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { apiUrl } from "../../../services/api";

import "./card.css";

type CardProps = {
  title: string;
  description: string;
  imageId: number;
  urlRedirect?: string;
  id: number;
  code: string;
};

export default function Card({ urlRedirect, title, description, imageId, id, code }: CardProps) {
  return (
    <RouterLink to={{
      pathname: urlRedirect ?? "/",
      state: id
    }}>
        <>
          <div
            className="card-product"
            style={{
              backgroundImage: `url('${apiUrl}/image/${imageId}')`,
            }}
          ></div>
          <div className="card-product-content">
            <h3>{title}</h3>
            <h4>{code}</h4>
            <h4>{description}</h4>
          </div>
        </>
    </RouterLink>
  );
}
