import React, { ReactNode,useContext,useEffect } from "react";
import Loader from "../../../components/loader";
import { useHistory } from "react-router-dom";
import { Context as AuthContext } from "../../../contexts/AuthContext";

interface IProps {
  children: ReactNode;
}
export function UnauthenticatedLayout({ children }: IProps) {
  const { state: {isAuthenticated} } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/marcas");
    }
  }, [isAuthenticated]);

  return (
    <div>
      <Loader />
      {children}
    </div>
  );
}