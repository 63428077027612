import React, { Component } from "react";
import PropTypes from "prop-types";
import { SearchField } from "@adobe/react-spectrum";
import "./search.css";
import Search from "@spectrum-icons/workflow/Search";


interface IAutocompleteProps {
    suggestions: any[]
}

interface IAutocompleteState {
    activeSuggestion: number,
    filteredSuggestions: any[],
    showSuggestions: boolean,
    userInput: string
}


class Autocomplete extends Component<IAutocompleteProps, IAutocompleteState> {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
    };

    static defaultProps = {
        suggestions: [],
    };

    constructor(props: any) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: "",
        };
    }

    // Event fired when the input value is changed
    onChange = (value: any) => {
        const { suggestions } = this.props;
        const userInput = value;
        let filteredCodeSuggestions: any = [];
        let filteredPubSuggestions: any = [];
        // Filter our suggestions that don't contain the user's input
        let filteredSuggestions = suggestions.filter(
            (suggestion) =>
                suggestion?.text.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        
        for (let i = 0; i < suggestions.length; i++) {
            if (suggestions[i].code === userInput && suggestions[i].published == true) {
                filteredCodeSuggestions.push(suggestions[i]);
                filteredSuggestions = filteredCodeSuggestions;
            } 
        }
        for (let i = 0; i < filteredSuggestions.length; i++) {
            if (filteredSuggestions[i].published == false) {
                filteredSuggestions.splice(i,1);
            }
        }
        // console.log(filteredSuggestions);//para control
        for (let i = 0; i < filteredSuggestions.length; i++) {
            if (!filteredSuggestions[i].text.includes(filteredSuggestions[i].code)) {
                filteredSuggestions[i].text = filteredSuggestions[i].text+" "+ filteredSuggestions[i].code;
            }
             
        }

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: value,
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = (e: any, suggestion: any) => {
        // Update the user input and reset the rest of the state
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
        });
    };

    // Event fired when the user presses a key down
    onKeyDown = (e: any) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion],
            });
        }

        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
            },
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion: any, index: any) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion.key}
                                    onClick={(e) => suggestion.handler(suggestion)}
                                >
                                    {suggestion.text}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        <li className="suggestion-active">
                            <i>No se encuentra resultados. !Probá con otra palabra clave!</i>
                        </li>
                    </ul>
                );
            }
        }

        return (
            <>
                <SearchField
                    id="searchField"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={userInput}
                    autoComplete="off"
                    icon={<Search size="XXL" id="searchIcon" />}
                />
                {suggestionsListComponent}
            </>
        );
    }
}

export default Autocomplete;

