import React, { ReactNode } from "react";
import "./sidebar.css";
import Scrollable from "../scrollable";

interface ISidebarProps {
  children: ReactNode;
}

export default function Sidebar({ children }: ISidebarProps) {
  return <Scrollable height="530px" width="15%">{children}</Scrollable>;
}
