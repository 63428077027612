import React from 'react';
import "./footer.css";

interface IProps {
  version: string;
}

export default function Footer({ version }: IProps) {
  return (
    <footer className="footer">
      <div  className="footer-container">
        <p className="footer-brand">LA SERENISIMA</p>
        <div>
          <p className="footer-copyright">TODOS LOS DERECHOS RESERVADOS MASTELLONE HERMANOS S.A.</p>
          <p className="footer-version">Versión: {version ? version : "cargando..."}</p>
        </div>
      </div>
    </footer>
  );
}
