import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { apiUrl } from "../../../services/api";

import "./card.css";

type CardProps = {
  title: string;
  imageId: number;
  urlRedirect?: string;
  id: number;
};

export default function Card({ urlRedirect, title, imageId, id }: CardProps) {
  return (
    <RouterLink to={{
      pathname: urlRedirect ?? "/",
      state: id
    }}>
      <>
        <div
          className="card-category"
          style={{
            backgroundImage: `url('${apiUrl}/image/${imageId}')`,
          }}
        >
          <div>
            <h2>{title}</h2>
          </div>
        </div>
      </>
    </RouterLink>
  );
}
