import React from "react";
import {apiUrl} from "../../services/api";

type ImageProps = {
  imageId: string;
};

export default function Image({ imageId }: ImageProps) {
  return (
      <img width="18px" style={{position:"relative", top:"3px"}} src={`${apiUrl}/image/${imageId}`} alt="img"></img>
  );
}
