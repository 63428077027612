import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { apiUrl } from "../../../services/api";

import "./card.css";

type CardProps = {
  title: string;
  imageId: number;
  urlRedirect?: string;
};

export default function Card({ urlRedirect, title, imageId }: CardProps) {
  return (
    <RouterLink  to={urlRedirect ?? "/"}>
      <>
        <div
          className="card-brand"
          style={{
            backgroundImage: `url('${apiUrl}/image/${imageId}')`,
          }}
        >
          <div>
            <h2>{title}</h2>
          </div>
        </div>
      </>
    </RouterLink>
  );
}
