import createDataContext from "./createDataContext";
import api from "../services/api";
import { getUsername } from "./AuthContext";

interface Response {
  data: IProduct;
}

export interface IProduct {
  denomination: string;
  description: string;
  code: string;
}

const productReducer = (state: any, action: any) => {
  switch (action.type) {
    case "fetch_products":
      return { ...state, products: action.payload };
    case "fetch_products_comparison":
      return { ...state, products: action.payload };
    case "fetch_product_by_id_category":
      return { ...state, products: action.payload };
    case "fetch_product_by_id_brandcategory":
      return { ...state, products: action.payload };
    case "fetch_products_by_filter_category":
      return { ...state, products: action.payload };
    case "fetch_products_by_filter_category2":
      return { ...state, products: action.payload };
    case "fetch_products_by_search_autocomplete":
      return { ...state, products_search: action.payload };
    case "fetch_product_by_id":
      return { ...state, product: action.payload };
    case "fetch_products_relative_by_id_category":
      return { ...state, relatives: action.payload };
    default:
      return state;
  }
};

const fetchProducts = (dispatch: any) => async (id: number) => {
  const response = await api.get(`/product`);
  dispatch({ type: "fetch_products", payload: response.data });
};

const fetchProductComparison = (dispatch: any) => async (id: number) => {
  const response = await api.get(`/product/byCategory/${id}`);
  dispatch({ type: "fetch_products_comparison", payload: response.data });
};

const fetchProductByIdCategory = (dispatch: any) => async (id: number) => {
  const response = await api.get(`/category/${id}/products`);
  dispatch({ type: "fetch_product_by_id_category", payload: response.data });
};

const fetchProductByIdBrandCategory = (dispatch: any) => async (bId: number, cId: number) => {
  const response = await api.get(`/category/${bId}/${cId}/products&${getUsername()}`);
  dispatch({ type: "fetch_product_by_id_brandcategory", payload: response.data });
};

const fetchProductsByFilterAndCategory = (dispatch: any) => async (
  categoryId: number,
  filter: any,
) => {
  const response = await api.post(`/product/search`, {
    categoryId: categoryId,
    characteristic: filter,
  });
  dispatch({
    type: "fetch_products_by_filter_category",
    payload: response.data,
  });
};

const fetchProductsByFilterAndCategory2 = (dispatch: any) => async (
  categoryId: number,
  filter: any,
  productIds: any,
  characteristic: any
) => {
  console.log(productIds);
  const response = await api.post(`/product/search`, {
    categoryId: categoryId,
    description: filter,
    productIds: productIds,
    characteristic: characteristic
  });
  dispatch({
    type: "fetch_products_by_filter_category2",
    payload: response.data,
  });
};

const getProductById = (dispatch: any) => async (id: number) => {
  const response = await api.get<Response>(`/product/${id}`);
  dispatch({ type: "fetch_product_by_id", payload: response.data });
};

const fetchProductsByAutoComplete = (dispatch: any) => async (filter: any) => {
  const response = await api.post(`/product/search_autocomplete`, {});
  //console.log(response.data);
  dispatch({
    type: "fetch_products_by_search_autocomplete",
    payload: response.data,
  });
};



const getProducsRelative = (dispatch: any) => async (id: number, idCategory: number) => {
  const response = await api.get(`/category/${idCategory}/products`);
  let products = response.data;
  let i = products.findIndex((e: any) => e.id === id);
  var previous = products[i == 0 ? products.length - 1 : i - 1];
  var current = products[i];
  var next = products[i == products.length - 1 ? 0 : i + 1];
  let result = { previous, current, next };
  dispatch({ type: "fetch_products_relative_by_id_category", payload: result });
};

class InitialState {
  products: IProduct[] = [];
  relatives: IProduct[] = [];
  products_search: IProduct[] = [];
  product: IProduct | null = null;
}

export const { Context, Provider } = createDataContext(
  productReducer,
  {
    fetchProducts,
    fetchProductComparison,
    fetchProductByIdCategory,
    fetchProductByIdBrandCategory,
    fetchProductsByFilterAndCategory,
    fetchProductsByFilterAndCategory2,
    fetchProductsByAutoComplete,
    getProductById,
    getProducsRelative
  },
  InitialState
);
